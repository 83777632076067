html {
  font-family: 'text,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol';
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-font-feature-settings: "kern";
  font-feature-settings: "kern";
  -webkit-font-kerning: normal;
  font-kerning: normal;
}
body {
  background: #ffcf56;
}
.main {
  text-align: center;
}


#drum-btn {
  width: 120px;
  height: 45px;
  font-size: 30px;
  cursor: pointer !important;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.10);
  transition: 0.15s ease-in-out all;
  margin: 0 auto;
  margin-top: 8em;
}
#drum-btn:hover {
  background: #f2f2f2;
}

.southern-drum {
  width: 300px;
  margin: 6em auto;
}
.on-the-bone {
  font-size: 16px;
  font-weight: 600;
  padding-top: 1em;
  color: #25291C;
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#chicken {
  display: none;
}

.drums-text {
  font-size: 100px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* box-shadow: 0 3px 8px 0 rgba(0,0,0,0.10); */
  /* background: #a0e8af; */
  margin: 0 auto;
  margin-top: 0.25em;
}
.coming {
  font-size: 15px;
  position: absolute;
  bottom: 2em;
  left: 0;
  right: 0;
  font-weight: 600;
  padding-right: 10px;
  color: #000;
}


@media (prefers-reduced-motion: no-preference) {
  .southern-drum {
    animation: drum-spin infinite 7s linear;
  }
}



@keyframes drum-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
